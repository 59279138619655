import React from 'react'
import { Segment } from 'components/segment/Segment';
import SEO from 'components/seo';

export default () => (
    <>
        <SEO title="Disclaimer page" />
        <Segment>
            <div style={{ minHeight: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <h1> Disclaimer</h1>
                <p>The information contained in this Request for Proposals document (“RFP”) or subsequently provided to Applicants, whether verbally or in documentary or any other form by or on behalf of the Authority or any of its employees or advisers, is provided to Applicants on the terms and conditions set out in this RFP and such other terms and conditions subject to which such information is provided.
                This RFP is not an agreement and is neither an offer nor invitation by the Authority to the prospective Applicants or any other person. The purpose of this RFP is to provide interested parties with information that may be useful to them in the formulation of their Proposals pursuant to this RFP. This RFP includes statements, which reflect various assumptions and assessments arrived at by the Authority in relation to the Consultancy. Such assumptions, assessments and statements do not purport to contain all the information that each Applicant may require. This RFP may not be appropriate for all persons, and it is not possible for the Authority, its employees or advisers to consider the objectives, technical expertise and particular needs of each party who reads or uses this RFP. The assumptions, assessments, statements and information contained in this RFP, may not be complete, accurate, adequate or correct. Each Applicant should, therefore, conduct its own investigations and analysis and should check the accuracy, adequacy, correctness, reliability and completeness of the assumptions, assessments and information contained in this RFP and obtain independent advice from appropriate sources.
                Information provided in this RFP to the Applicants is on a wide range of matters, some of which depends upon interpretation of law. The information given is not an exhaustive account of statutory requirements and should not be regarded as a complete or authoritative statement of law. The Authority accepts no responsibility for the accuracy or otherwise for any interpretation or opinion on the law expressed herein.
                The Authority, its employees and advisers make no representation or warranty and shall have no liability to any person including any Applicant under any law, statute, rules or regulations or tort, principles of restitution or unjust enrichment or otherwise for any loss, damages, cost or expense which may arise from or be incurred or suffered on account of anything contained in this RFP or otherwise, including the accuracy, adequacy, correctness, reliability or completeness of the RFP and any assessment, assumption, statement or information contained therein or deemed to form part of this RFP or arising in any way in this Selection Process.
                The Authority also accepts no liability of any nature whether resulting from negligence or otherwise, howsoever caused, arising from reliance of any Applicant upon the statements contained in this RFP.
                The Authority may in its absolute discretion, but without being under any obligation to do so, update, amend or supplement the information, assessment or assumption contained in this RFP.
                The issue of this RFP does not imply that the Authority is bound to select an Applicant or to appoint the Selected Applicant, as the case may be, for the Consultancy and the Authority reserves the right to reject all or any of the Proposals without assigning any reasons whatsoever.
                
                The Applicant shall bear all its costs associated with or relating to the preparation and submission of its Proposal including but not limited to preparation, copying, postage, delivery fees, expenses associated with any demonstrations or presentations which may be required by the Authority or any other costs incurred in connection with or relating to its Proposal. All such costs and expenses will remain with the Applicant and the Authority shall not be liable in any manner whatsoever for the same or for any other costs or other expenses incurred by an Applicant in preparation or submission of the Proposal, regardless of the conduct or outcome of the Selection Process.
                </p>
            </div>
        </Segment>
    </>
    )
